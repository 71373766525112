import Mixins from "../../Mixins";

export default {
  name: "EditLoanChartOfAccount",
  mixins: [Mixins],
  data() {
    return {
      identity: {
        loanId: "",
      },
      property: {
        modal: {
          showModalEditLoanChartOfAccount: false,
        },
        listElement: {
          loanChartOfAccount: {
            currentPage: 1,
            rows: 0,
            perPage: 5,
            message: "",
            downloading: false,
          },
        },
        animation: {
          addLoanChartOfAccount: {
            isLoading: false,
          },
          editLoanChartOfAccount: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        add: {
          productCategory: "",
          loanChartOfAccountId: "",
          isActive: false,
          chartOfAccountId: "",
          productLedgerId: "",
          akunLedgerDeskripsi: "",
          mutationPosition: "",
          pairChartOfAccountId: "",
          pairMutationPosition: "",
          transactionCodeId: "",
          pairType: "",
        },
        edit: {
          productCategory: "",
          loanChartOfAccountId: "",
          isActive: false,
          chartOfAccountId: "",
          productLedgerId: "",
          akunLedgerDeskripsi: "",
          mutationPosition: "",
          pairChartOfAccountId: "",
          pairMutationPosition: "",
          transactionCodeId: "",
          pairType: "",
        },
      },
      options: {
        parameter: [],
        akunLedger: [],
        posisiMutasi: [],
        transactionCode: [],
      },
      table: {
        data: {
          loanChartOfAccount: [],
        },
      },
    };
  },
  methods: {
    changePairTypeAddLoanChartOfAccount() {
      if (this.dataForm.add.pairType === "SAVING") {
        this.dataForm.add.pairChartOfAccountId = "";
      }
    },
    changePairTypeEditLoanChartOfAccount() {
      if (this.dataForm.edit.pairType === "SAVING") {
        this.dataForm.edit.pairChartOfAccountId = "";
      }
    },
    async getLoanChartOfAccountById(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl: "loan-chart-of-account/" + props.row.loanChartOfAccountId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.edit.productCategory = resp.data.data.mloan
              ? resp.data.data.mloan.mproductCategory
                ? resp.data.data.mloan.mproductCategory.productCategoryName
                : ""
              : "";
            this.dataForm.edit.isActive = resp.data.data.isActive;
            this.dataForm.edit.chartOfAccountId = resp.data.data.chartOfAccount
              ? resp.data.data.chartOfAccount.chartOfAccountId
              : "";
            this.dataForm.edit.productLedgerId = resp.data.data.productLedger
              ? resp.data.data.productLedger.productLedgerId
              : "";
            this.dataForm.edit.loanChartOfAccountId =
              resp.data.data.loanChartOfAccountId;
            this.dataForm.edit.akunLedgerDeskripsi = resp.data.data
              .chartOfAccount
              ? resp.data.data.chartOfAccount.description
              : "";
            this.dataForm.edit.mutationPosition =
              resp.data.data.mutationPosition;
            this.dataForm.edit.pairChartOfAccountId = resp.data.data
              .pairChartOfAccount
              ? resp.data.data.pairChartOfAccount.chartOfAccountId
              : "";
            this.dataForm.edit.pairMutationPosition = resp.data.data
              .pairMutationPosition
              ? resp.data.data.pairMutationPosition
              : "";
            this.dataForm.edit.transactionCodeId = resp.data.data
              .transactionCode
              ? resp.data.data.transactionCode.transactionCodeId
              : "";
            this.dataForm.edit.pairType = resp.data.data.pairType;
            this.property.modal.showModalEditLoanChartOfAccount = true;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    resetFormLoanChartOfAccount(type) {
      switch (type) {
        case "ADD":
          this.dataForm.add.isActive = false;
          this.dataForm.add.chartOfAccountId = "";
          this.dataForm.add.productLedgerId = "";
          this.dataForm.add.loanChartOfAccountId = "";
          this.dataForm.add.akunLedgerDeskripsi = "";
          this.dataForm.add.mutationPosition = "";
          this.dataForm.add.pairChartOfAccountId = "";
          this.dataForm.add.pairType = "";
          this.dataForm.add.pairMutationPosition = "";
          this.dataForm.add.transactionCodeId = "";
          break;

        case "EDIT":
          this.dataForm.edit.isActive = false;
          this.dataForm.edit.chartOfAccountId = "";
          this.dataForm.edit.productLedgerId = "";
          this.dataForm.edit.loanChartOfAccountId = "";
          this.dataForm.edit.akunLedgerDeskripsi = "";
          this.dataForm.edit.productCategory = "";
          this.dataForm.edit.mutationPosition = "";
          this.dataForm.edit.pairChartOfAccountId = "";
          this.dataForm.edit.pairType = "";
          this.dataForm.edit.pairMutationPosition = "";
          this.dataForm.edit.transactionCodeId = "";
          break;

        default:
          return;
          break;
      }
    },
    async deleteLoanChartOfAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-chart-of-account/" + props.row.loanChartOfAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getLoanChartOfAccount();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async getLoanChartOfAccount() {
      this.table.data.loanChartOfAccount = [];
      this.property.listElement.loanChartOfAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-chart-of-account",
          payload: {
            loanChartOfAccountId: "",
            loanId: this.identity.loanId,
            isActive: true,
            page: 0,
            size: this.property.listElement.loanChartOfAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanChartOfAccount = resp.data.data.content;
            this.property.listElement.loanChartOfAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.property.listElement.loanChartOfAccount.message =
              resp.data.message;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanChartOfAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanChartOfAccount.downloading = false;
        }, timeout);
      }
    },
    handleErrorLoanChartOfAccount(error) {
      console.log(error.response);
      this.table.data.loanChartOfAccount = [];
      this.property.listElement.loanChartOfAccount.rows = 0;
      this.property.listElement.loanChartOfAccount.currentPage = 1;
      this.property.listElement.loanChartOfAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async editLoanChartOfAccount() {
      const payload = {
        loanChartOfAccountId: this.dataForm.edit.loanChartOfAccountId,
        isActive: this.dataForm.edit.isActive,
        chartOfAccountId: this.dataForm.edit.chartOfAccountId,
        productLedgerId: this.dataForm.edit.productLedgerId,
        loanId: this.identity.loanId,
        mutationPosition: this.dataForm.edit.mutationPosition,
        pairChartOfAccountId: this.dataForm.edit.pairChartOfAccountId,
        pairType: this.dataForm.edit.pairType,
        transactionCodeId: this.dataForm.edit.transactionCodeId,
        pairMutationPosition: this.dataForm.edit.pairMutationPosition,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.editLoanChartOfAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl:
                  "loan-chart-of-account/" +
                  this.dataForm.edit.loanChartOfAccountId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.property.modal.showModalEditLoanChartOfAccount = false;
                  setTimeout(() => {
                    this.$buefy.toast.open(this.$NotificationUtils.success);
                    setTimeout(() => {
                      this.resetFormLoanChartOfAccount("EDIT");
                      this.getLoanChartOfAccount();
                    }, 500);
                  }, 1000);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.editLoanChartOfAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async addLoanChartOfAccount() {
      const payload = {
        loanChartOfAccountId: "",
        isActive: this.dataForm.add.isActive,
        chartOfAccountId: this.dataForm.add.chartOfAccountId,
        productLedgerId: this.dataForm.add.productLedgerId,
        loanId: this.identity.loanId,
        mutationPosition: this.dataForm.add.mutationPosition,
        pairChartOfAccountId: this.dataForm.add.pairChartOfAccountId,
        pairType: this.dataForm.add.pairType,
        pairMutationPosition: this.dataForm.add.pairMutationPosition,
        transactionCodeId: this.dataForm.add.transactionCodeId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Simpan Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          this.property.animation.addLoanChartOfAccount.isLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "POST_DATA",
              endPoint: "loan",
              reqUrl: "loan-chart-of-account",
              payload: payload,
            });
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                const toastBuefy = this.$buefy.toast.open(
                  this.$NotificationUtils.success
                );
                toastBuefy.$on("close", () => {
                  this.resetFormLoanChartOfAccount("ADD");
                  this.getLoanChartOfAccount();
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            }, 1500);
          } catch (error) {
            setTimeout(() => {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }, 1500);
          } finally {
            setTimeout(() => {
              this.property.animation.addLoanChartOfAccount.isLoading = false;
            }, 1000);
          }
        },
      });
    },
    changeAkunLedgerLoanChartOfAccountAdd() {
      this.dataForm.add.akunLedgerDeskripsi = "";
      this.options.akunLedger.map((index) => {
        if (index.value === this.dataForm.add.chartOfAccountId) {
          this.dataForm.add.akunLedgerDeskripsi = index.description;
        }
      });
    },
    changeAkunLedgerLoanChartOfAccountEdit() {
      this.dataForm.edit.akunLedgerDeskripsi = "";
      this.options.akunLedger.map((index) => {
        if (i.value === this.dataForm.edit.chartOfAccountId) {
          this.dataForm.edit.akunLedgerDeskripsi = index.description;
        }
      });
    },
    async getProductCategoryId() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_PRODUCT_CATEGORY"
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            if (
              index.productCategoryId ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_PRODUCT_CATEGORY_LOAN
            ) {
              this.dataForm.add.productCategory = index.productCategoryName;
            }
          });
        }
      } catch (error) {}
    },
    async getReferenceProductLedger() {
      try {
        const resp = await this.$store.dispatch(
          "GET_PRODUCT_LEDGER_BY_PRODUCT_CATEGORY_ID",
          {
            productCategoryId: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_PRODUCT_CATEGORY_LOAN,
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.productLedgerName;
            const value = index.productLedgerId;
            this.options.parameter.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              description: "",
              page: 0,
            },
            url: "chart-of-account",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const value = i.chartOfAccountId;
            const text = `${i.chartOfAccountCode} - ${i.description}`;
            const description = i.description;
            this.options.akunLedger.push({ text, value, description });
          });
        }
      } catch (error) {}
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              page: 0,
              name: "",
            },
            url: "transaction-code",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCodeId;
            this.options.transactionCode.push({ text, value });
          });
        }
      } catch (error) {}
    },
    closeModalEditLoanChartOfAccount() {
      this.property.modal.showModalEditLoanChartOfAccount = false;
      this.resetFormLoanChartOfAccount("EDIT");
    },
    async changePaginationLoanChartOfAccount(event) {
      this.table.data.loanChartOfAccount = [];
      this.property.listElement.loanChartOfAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-chart-of-account",
          payload: {
            loanChartOfAccountId: "",
            loanId: this.identity.loanId,
            isActive: true,
            page: event - 1,
            size: this.property.listElement.loanChartOfAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanChartOfAccount = resp.data.data.content;
            this.property.listElement.loanChartOfAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataLoanChartOfAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorLoanChartOfAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanChartOfAccount.downloading = false;
        }, timeout);
      }
    },
    failedGetDataLoanChartOfAccount(resp) {
      this.table.data.loanChartOfAccount = [];
      this.property.listElement.loanChartOfAccount.rows = 0;
      this.property.listElement.loanChartOfAccount.message = resp.data.message;
    },
    getIdentityFromMixin() {
      this.identity.loanId = this.mixin_data_identifier_loan_id;
    },
    appendOptionsPosisiMutasi() {
      this.options.posisiMutasi = [
        { value: "D", text: "Debet" },
        { value: "C", text: "Credit" },
      ];
    },
  },
  mounted() {
    this.getIdentityFromMixin();
    this.getProductCategoryId();
    this.getReferenceProductLedger();
    this.getReferenceChartOfAccount();
    this.getReferenceTransactionCode();
    this.getLoanChartOfAccount();
    this.appendOptionsPosisiMutasi();
  },
};
