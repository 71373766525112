import Mixins from "../../Mixins";

export default {
  name: "EditLoan",
  mixins: [Mixins],
  data() {
    return {
      identity: {
        loanId: "",
      },
      property: {
        animation: {
          editLoan: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        loanCode: "",
        loanName: "",
        currencyId: "",
        loanTypeId: "",
        akadTypeId: "",
        installmentPayId: "",
        incomeTypeId: "",
        dpdDay: "",
        loanCollectabilityCode: "",
        latechargePercentPerday: "",
        latechargeNominalMax: "",
        isAutodebtAccount: false,
        etapLoanMin: "",
        marginCalculateId: "",
        provisionMin: "",
        provisionPercent: "",
        adminCostPercent: "",
        adminCostMin: "",
        bankRoleId: "",
        productCategoryId: "",
        chartOfAccountId: "",
        marginCoaId: "",
        incomeCoaId: "",
        ppapCoaId: "",
      },
      options: {
        mataUang: [],
        kelompokPenyaluran: [],
        bentukPenyaluranDana: [],
        settingPembayaran: [],
        perananBank: [],
        jenisPendapatan: [],
        kodeKolektibilitas: [],
        metodeBayarDenda: [],
        metodePerhitunganMargin: [],
        parameterCoaList: [],
        loanCollectability: [],
      },
    };
  },
  methods: {
    getIdentityFromSession() {
      var getTokenFromSession = sessionStorage.getItem("LOAN_ID");
      this.identity.loanId = getTokenFromSession;
      this.$store.dispatch("SET_IDENTIFIER_LOAN_ID", getTokenFromSession);
    },
    async editLoan() {
      const payload = {
        loanCode: this.dataForm.loanCode,
        loanName: this.dataForm.loanName,
        currencyId: this.dataForm.currencyId,
        loanAkadTypeId: this.dataForm.loanAkadTypeId,
        akadTypeId: this.dataForm.akadTypeId,
        installmentPayId: this.dataForm.installmentPayId,
        incomeTypeId: this.dataForm.incomeTypeId,
        dpdDay: this.dataForm.dpdDay,
        loanCollectabilityCode: this.dataForm.loanCollectabilityCode,
        latechargePercentPerday: this.dataForm.latechargePercentPerday,
        latechargeNominalMax: this.dataForm.latechargeNominalMax,
        isAutodebtAccount: this.dataForm.isAutodebtAccount,
        etapLoanMin: this.dataForm.etapLoanMin,
        marginCalculateId: this.dataForm.marginCalculateId,
        provisionMin: this.dataForm.provisionMin,
        provisionPercent: this.dataForm.provisionPercent,
        adminCostPercent: this.dataForm.adminCostPercent,
        adminCostMin: this.dataForm.adminCostMin,
        bankRoleId: this.dataForm.bankRoleId,
        installmentPayId: this.dataForm.installmentPayId,
        lateChargePayId: this.dataForm.lateChargePayId,
        loanTypeId: this.dataForm.loanTypeId,
        productCategoryId: this.dataForm.productCategoryId,
        chartOfAccountId: this.dataForm.chartOfAccountId,
        marginCoaId: this.dataForm.marginCoaId,
        incomeCoaId: this.dataForm.incomeCoaId,
        ppapCoaId: this.dataForm.ppapCoaId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.editLoan.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl: "loan/" + this.identity.loanId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.editLoan.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async getReferenceCurrency() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              currencyName: "",
              page: 0,
            },
            url: "m-currency",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = index.currencyName;
            const value = index.currencyId;
            this.options.mataUang.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceloanAkadType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanAkadTypeName;
            const value = index.loanAkadTypeId;
            this.options.bentukPenyaluranDana.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanBankRole() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-bank-role",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanBankRoleName;
            const value = index.loanBankRoleId;
            this.options.perananBank.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanTypeName;
            const value = index.loanTypeId;
            this.options.kelompokPenyaluran.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanIncomeType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-income-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanIncomeTypeName;
            const value = index.loanIncomeTypeId;
            this.options.jenisPendapatan.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanCollectability() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collectability-code",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.loanCollectability = [
            { value: "", text: "-- Pilih --" },
          ];
          resp.data.data.map((index) => {
            const text = `${index.collectabilityCodeName}`;
            const value = index.collectabilityCodeId;
            this.options.loanCollectability.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanLateChargePay() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-late-charge-pay",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanLatechargePayName;
            const value = index.loanLatechargePayId;
            this.options.metodeBayarDenda.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanMarginCalculate() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-margin-calculate",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanMarginCalculateName;
            const value = index.loanMarginCalculateId;
            this.options.metodePerhitunganMargin.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanInstallmentPay() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-installment-pay",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanInstallmentPayName;
            const value = index.loanInstallmentPayId;
            this.options.settingPembayaran.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async helperGetParameterCoa() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "chart-of-account/list",
          endPoint: "parameter",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.accountNumber = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((index) => {
            const text = `${index.chartOfAccountCode} - ${index.description}`;
            const value = index.chartOfAccountId;
            this.options.parameterCoaList.push({
              text,
              value,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getLoanById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl: "loan/" + this.identity.loanId,
          });
          console.log("jajan terus => ", resp);
          if (resp.data.code === "SUCCESS") {
            this.dataForm.loanCode = resp.data.data.loanCode;
            this.dataForm.loanName = resp.data.data.loanName;
            this.dataForm.currencyId = resp.data.data.mcurrency
              ? resp.data.data.mcurrency.currencyId
              : "";
            this.dataForm.loanTypeId = resp.data.data.rloanType
              ? resp.data.data.rloanType.loanTypeId
              : "";
            this.dataForm.akadTypeId = resp.data.data.rloanAkadType
              ? resp.data.data.rloanAkadType.loanAkadTypeId
              : "";
            this.dataForm.installmentPayId = resp.data.data.rloanInstallmentPay
              ? resp.data.data.rloanInstallmentPay.loanInstallmentPayId
              : "";
            this.dataForm.incomeTypeId = resp.data.data.rloanIncomeType
              ? resp.data.data.rloanIncomeType.loanIncomeTypeId
              : "";
            this.dataForm.dpdDay = resp.data.data.dpdDay;
            this.dataForm.loanCollectabilityCode = resp.data.data
              .rloanCollectabilityCode
              ? resp.data.data.rloanCollectabilityCode.collectabilityCodeId
              : "";
            this.dataForm.latechargePercentPerday =
              resp.data.data.latechargePercentPerday;
            this.dataForm.latechargeNominalMax =
              resp.data.data.latechargeNominalMax;
            this.dataForm.isAutodebtAccount = resp.data.data.isAutodebtAccount;
            this.dataForm.etapLoanMin = resp.data.data.etapLoanMin;
            this.dataForm.marginCalculateId = resp.data.data
              .rloanMarginCalculate
              ? resp.data.data.rloanMarginCalculate.loanMarginCalculateId
              : "";
            this.dataForm.provisionMin = resp.data.data.provisionMin;
            this.dataForm.provisionPercent = resp.data.data.provisionPercent;
            this.dataForm.adminCostPercent = resp.data.data.adminCostPercent;
            this.dataForm.adminCostMin = resp.data.data.adminCostMin;
            this.dataForm.bankRoleId = resp.data.data.rloanBankRole
              ? resp.data.data.rloanBankRole.loanBankRoleId
              : "";
            this.dataForm.lateChargePayId = resp.data.data.rloanLatechargePay
              ? resp.data.data.rloanLatechargePay.loanLatechargePayId
              : "";
            this.dataForm.productCategoryId = resp.data.data.mproductCategory
              ? resp.data.data.mproductCategory.productCategoryId
              : "";
            this.dataForm.chartOfAccountId = resp.data.data.mchartOfAccount
              ? resp.data.data.mchartOfAccount.chartOfAccountId
              : "";
            this.dataForm.marginCoaId = resp.data.data.marginCoa
              ? resp.data.data.marginCoa.chartOfAccountId
              : "";
            this.dataForm.incomeCoaId = resp.data.data.incomeCoa
              ? resp.data.data.incomeCoa.chartOfAccountId
              : "";
            this.dataForm.ppapCoaId = resp.data.data.ppapCoa
              ? resp.data.data.ppapCoa.chartOfAccountId
              : "";
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListLoan(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    routeToPageListLoan() {
      this.$router.push("list");
    },
  },
  mounted() {
    this.getIdentityFromSession();
    this.getReferenceCurrency();
    this.getReferenceLoanType();
    this.getReferenceloanAkadType();
    this.getReferenceLoanBankRole();
    this.getReferenceLoanIncomeType();
    this.getReferenceLoanCollectability();
    this.getReferenceLoanMarginCalculate();
    this.getReferenceLoanLateChargePay();
    this.getReferenceLoanInstallmentPay();
    this.helperGetParameterCoa();
    this.getLoanById();
  },
};
